<template>
  <div class="footer-line" :class="{ squish: mobileSquish, centered }">
    <Accordion
      v-for="section of footerSections"
      :key="section?.id"
      v-slot="{ toggle }"
      class="section"
      :class="{ openable, icons: isIconOnly(section) }"
      :disabled="isIconOnly(section) || !openable"
      mobile-only
    >
      <div class="title-container" @click="toggle">
        <div class="title">{{ section?.title }}</div>
        <Icon class="accordion-arrow" name="arrow-footer-down" />
      </div>
      <div class="title-separator" />
      <div class="links-container">
        <div class="links accordion-content">
          <template v-for="link of section?.links" :key="link.id">
            <Icon v-if="link.icon" :name="link.icon" />
            <NuxtLink v-else :to="`${link.url}/`" class="link">{{ link.name }}</NuxtLink>
          </template>
        </div>
      </div>
    </Accordion>
  </div>
</template>

<script lang="ts" setup>
import { FooterSection } from '@/lib/strapi-types/components/FooterSection';
import { MvCountry } from '@/lib/strapi-types/MvCountry';
import { firstLetterMajor, replacePlaceholders } from '@/lib/utils';
import { Link } from '@/lib/types/practicalInformationFooter';
import { RouteLocationNormalized } from '@/.nuxt/vue-router-stub';

const {
  openable = false,
  mobileSquish = false,
  centered = false
} = defineProps<{
  openable?: boolean;
  mobileSquish?: boolean;
  centered?: boolean;
}>();
const route = useRoute();
const footerStore = useFooterStore();
footerStore.fetchFooter();

const { sections, practicalInformations } = storeToRefs(footerStore);
const activitiesSection = ref<FooterSection | null>(null);
const practicalInfoSection = ref<FooterSection | null>(null);

const footerSections = computed(() => {
  return [
    ...(activitiesSection.value ? [activitiesSection.value] : []),
    ...(practicalInfoSection.value ? [practicalInfoSection.value] : []),
    ...sections.value
  ];
});
async function loadCountryData(countryId: string) {
  const [countryResponse] = await Promise.all([
    useFindOneCountry({
      filters: { slug: countryId },
      fields: ['practicalInformationsFooterLinks', 'preposition', 'slug', 'label']
    })
  ]);

  return { country: countryResponse.country };
}

if (practicalInformations.value.length > 0) {
  practicalInfoSection.value = {
    id: 3,
    title: 'Infos Pratiques',
    links: [...practicalInformations.value]
  };
}

if (route.params.countryid) {
  const { country } = await loadCountryData(route.params.countryid);

  const activitesLink = createActivityLinks(country, route);
  if (activitesLink.length) {
    activitiesSection.value = {
      id: 1,
      title: 'Activités',
      links: activitesLink
    };
  }
  const practicalInfoLink = createpracticalInfoLinks(country, route);
  if (practicalInfoLink?.length) {
    practicalInfoSection.value = {
      id: 2,
      title: country?.attributes.practicalInformationsFooterLinks?.title ?? '',
      links: [...practicalInfoLink, ...practicalInformations.value]
    };
  }
}

function createActivityLinks(country?: MvCountry, route?: RouteLocationNormalized) {
  return [
    {
      id: 1,
      name: `Toutes nos activités ${country?.attributes.preposition} ${country?.attributes.slug}`,
      url: `/${route?.params.continentid}/${route?.params.countryid}/activites`
    }
  ];
}

function createpracticalInfoLinks(country?: MvCountry, route?: RouteLocationNormalized) {
  if (!country?.attributes.practicalInformationsFooterLinks) return [];

  return country?.attributes.practicalInformationsFooterLinks.links?.map(
    (link: Link, index: number) => ({
      id: index,
      name: replacePlaceholders(link.name, {
        PAYS: firstLetterMajor(country.attributes.label as string),
        PREPOSITION: country?.attributes.preposition as string
      }),
      url: replacePlaceholders(link.url, {
        PREPOSITION: country?.attributes.preposition as string,
        SLUG_CONTINENT: route?.params.continentid as string,
        SLUG_PAYS: route?.params.countryid as string
      })
    })
  );
}

function isIconOnly(section: FooterSection | null): boolean {
  return !!section?.links.every(s => s.icon);
}
</script>

<style lang="scss" scoped>
@use '$/animation.scss';
@use '$/breakpoints.scss';

.footer-line {
  gap: 50px;
  justify-content: space-between;

  @include breakpoints.mobile() {
    flex-direction: column;
    gap: 24px;
  }

  &.squish {
    @include breakpoints.mobile() {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &.centered {
    gap: 8vw;
    justify-content: center;
  }

  .section {
    $section-gap: 16px;

    flex: 1;
    flex-direction: column;
    gap: $section-gap;

    @include breakpoints.mobile() {
      width: initial;
    }

    .title-container {
      display: contents;

      .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;

        @include breakpoints.mobile() {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }

    &:not(.icons).openable .title-container {
      @include breakpoints.mobile() {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .title-separator {
      border-top: dashed 2px white;
    }

    &.icons .title-separator {
      @include breakpoints.mobile() {
        display: none;
      }
    }

    .links-container {
      display: contents;

      .links {
        flex-direction: column;
        gap: 8px;

        .link {
          font-size: 14px;
          line-height: 23px;
          color: white;
          white-space: pre-wrap;

          @include breakpoints.mobile() {
            font-size: 16px;
            line-height: 19px;
          }
        }

        .link:hover {
          cursor: pointer;
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }

    &:not(.icons).openable .links-container {
      @include breakpoints.mobile() {
        display: flex;
        margin-top: -$section-gap;
      }

      .links {
        @include breakpoints.mobile() {
          gap: 11px;
          padding-top: $section-gap;
        }
      }
    }

    &.icons .links-container {
      @include breakpoints.mobile() {
        justify-content: center;
      }

      .links {
        flex-direction: row;
        gap: 15px;

        @include breakpoints.mobile() {
          gap: 25px;
          justify-content: center;
          margin-top: 20px;
        }
      }
    }
  }

  &.squish .section .links-container .links {
    @include breakpoints.mobile() {
      margin-top: -5px;
    }

    .link {
      @include breakpoints.mobile() {
        font-size: 12px;
        line-height: 24px;
      }
    }
  }
}
</style>
